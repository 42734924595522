<template>
    <div class="container move-up">
        <div class="row mb-5">
            <div class="col" v-if="showHeader">
                <TitleSection title="View more case studies" />
            </div>
            <div class="col">
                <SliderSplide :items="items" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import type MoreContentViewModel from '@/types/models/store/more-content-view-model';

    const items = ref<MoreContentViewModel[]>([
        {
            url: '/intumescent-paint-case-studies/memorial-building-deerfield-academy-deerfield-massachusetts',
            imgSet: {
                imageUrl: '/images/case-studies/memorial-building-deerfield-academy-deerfield-massachusetts/Memorial_Building@1x.webp',
                imageAlt: 'Memorial Building, Deerfield Academy, Deerfield,Massachusetts'
            },
            imgSetAvailable: true,
            imgSetMax: 3,
            title: 'Memorial Building, Deerfield Academy, Deerfield, Massachusetts',
        },
        {
            url: '/intumescent-paint-case-studies/ambling-construction-group-atlanta-georgia',
            imgSet: {
                imageUrl: '/images/case-studies/ambling-construction-group-atlanta-georgia/acc@1x.webp',
                imageAlt: 'Ambling Construction Group, Atlanta, Georgia'
            },
            imgSetAvailable: true,
            imgSetMax: 3,
            title: 'Ambling Construction Group, Atlanta, Georgia',
        },
        {
            url: '/intumescent-paint-case-studies/world-cup-2022-stadium-achievs-2-hour-fire-rating-on-gypsum',
            imgSet: {
                imageUrl: '/images/case-studies/world-cup-2022-stadium-achievs-2-hour-fire-rating-on-gypsum/stadium_case@1x.webp',
                imageAlt: ' World Cup 2022 Stadium Achieves 2 Hour Fire Rating on Gypsum'
            },
            imgSetAvailable: true,
            imgSetMax: 3,
            title: 'World Cup 2022 Stadium Achieves 2 Hour Fire Rating on Gypsum',
        },
        {
            url: '/intumescent-paint-case-studies/arc-partners-roswell-georgia',
            imgSet: {
                imageUrl: '/images/case-studies/arc-partners-roswell-georgia/mobile@1x.webp',
                imageAlt: 'ARC Partners, Roswell, Georgia'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'ARC Partners, Roswell, Georgia',
        },
        {
            url: '/intumescent-paint-case-studies/market-street-hotel-augusta-georgia',
            imgSet: {
                imageUrl: '/images/case-studies/market-street-hotel-augusta-georgia/mobile@1x.webp',
                imageAlt: 'Market Street Hotel, Augusta, Georgia'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'Market Street Hotel, Augusta, Georgia',
        },
        {
            url: '/intumescent-paint-case-studies/bell-clark-nashville-tn',
            imgSet: {
                imageUrl: '/images/case-studies/bell-clark-nashville-tn/mobile@1x.webp',
                imageAlt: 'Bell/Clark, Nashville, TN'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'Bell/Clark, Nashville, TN',
        },
        {
            url: '/intumescent-paint-case-studies/bartson-construction-atlanta-georgia',
            imgSet: {
                imageUrl: '/images/case-studies/bartson-construction-atlanta-georgia/mobile@1x.webp',
                imageAlt: 'Bartson Construction, Atlanta, Georgia'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'Bartson Construction, Atlanta, Georgia',
        },
        {
            url: '/intumescent-paint-case-studies/amu-surgeons',
            imgSet: {
                imageUrl: '/images/case-studies/amu-surgeons/mobile@1x.webp',
                imageAlt: 'AMU Surgeons'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'AMU Surgeons',
        },
        {
            url: '/intumescent-paint-case-studies/giland-townhomes',
            imgSet: {
                imageUrl: '/images/case-studies/giland-townhomes/mobile@1x.webp',
                imageAlt: 'Giland Townhomes'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'Giland Townhomes',
        },
        {
            url: '/intumescent-paint-case-studies/lenox-properties',
            imgSet: {
                imageUrl: '/images/case-studies/lenox-properties/mobile@1x.webp',
                imageAlt: 'Lenox Properties'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'Lenox Properties',
        },
        {
            url: '/intumescent-paint-case-studies/colon-eye-clinic',
            imgSet: {
                imageUrl: '/images/case-studies/colon-eye-clinic/mobile@1x.webp',
                imageAlt: 'Colon Eye Clinic'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'Colon Eye Clinic',
        },
        {
            url: '/intumescent-paint-case-studies/turner-barn',
            imgSet: {
                imageUrl: '/images/case-studies/turner-barn/mobile@1x.webp',
                imageAlt: 'Turner Barn'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'Turner Barn',
        },
        {
            url: '/intumescent-paint-case-studies/urban-housing-authority',
            imgSet: {
                imageUrl: '/images/case-studies/urban-housing-authority/mobile@1x.webp',
                imageAlt: 'Urban Housing Authority'
            },
            imgSetAvailable: true,
            imgSetMax: 2,
            title: 'Urban Housing Authority',
        }
    ]);

    defineProps({
        showHeader: {
            type: Boolean,
            default: true
        }
    });
</script>

<style lang="scss">
    @import '@/assets/styles/bootstrap/common';

    @include media-breakpoint-up(lg) {
        .move-up {
            margin-top: -4.375rem;
        }
    }
</style>